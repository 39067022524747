import React, { useState, useEffect } from "react";
import { Input, Form, Button, Row, Col } from "reactstrap";
import { db } from "../firebaseConfig";
import { UncontrolledAccordion, AccordionItem, AccordionHeader, AccordionBody } from "reactstrap";

function Search() {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCollection, setSelectedCollection] = useState("cZ0FKOtwfirxIcip7rFb"); // Default collection is "Kamu"
  const [searchResults, setSearchResults] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collectionRef = db.collection(selectedCollection);
        const snapshot = await collectionRef.get();
        const fetchedData = snapshot.docs.map((doc) => {
          const docData = doc.data();
          const values = JSON.parse(docData.values);
          return {
            id: doc.id,
            ...values.reduce((acc, val) => ({ ...acc, ...val }), {}),
          };
        });
        setData(fetchedData);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, [selectedCollection]);

  const handleSearch = (e) => {
    e.preventDefault();

    const filteredResults = data.filter((result) => {
      const { phone, title, brief, description, slungs } = result;
      const searchText = searchTerm.toLowerCase();

      return (
        (phone && phone.toLowerCase().includes(searchText)) ||
        (title && title.toLowerCase().includes(searchText)) ||
        (brief && brief.toLowerCase().includes(searchText)) ||
        (description && description.toLowerCase().includes(searchText)) ||
        (slungs && slungs.toLowerCase().includes(searchText))
      );
    });

    setSearchResults(filteredResults);
  };

  return (
    <div style={{ maxWidth: 900, margin: "auto", marginTop:"7vh" }}>
      <Form onSubmit={handleSearch}>
        <Row>
          <Col>
            <Input
              type="select"
              value={selectedCollection}
              onChange={(e) => setSelectedCollection(e.target.value)}
            >
              <option value="cZ0FKOtwfirxIcip7rFb">Kamu</option>
              <option value="CeUQxGuxj1seUdTTq3V8">Özel</option>
            </Input>
          </Col>
          <Col>
            <Input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Col>
          <Col>
            <Button  color="danger" type="submit">SORGULA</Button>
          </Col>
        </Row>
      </Form>

      <div style={{marginTop:"7vh"}}>
        {/* Render search results in accordions */}
        <UncontrolledAccordion flush>
          {searchResults.map((result, index) => (
            <AccordionItem >
              <AccordionHeader>
                <p style={{ fontFamily: "inherit", marginBottom: 0 }}>
                  <span>{result.phone}</span> | <span>{result.title}</span>
                </p>
              </AccordionHeader>
              <AccordionBody>
                <strong>{result.brief}</strong>
                <br />
                {result.description}
              </AccordionBody>
            </AccordionItem>
          ))}
        </UncontrolledAccordion>
      </div>
    </div>
  );
}

export default Search;
