import React from "react";

function AboutUs() {
  return (
    <div>
      <h3 style={{margin:"auto", textAlign:"center"}}>Hakkımızda</h3>
      <p style={{
          padding: "4vh",
          margin: "auto",
          textAlign: "center",
          marginTop: "2vh",
          fontSize: "0.9rem",
          maxWidth:"900px"
          // borderColor:"black", borderBlock:"solid"
        }}>
        Şikayet bilgi sistemi, anayasamızda bulunan haklarımız çerçevesinde,
        devletimizin; bakanlıklar,kamu kurumları ve tüzel kişiler aracılığı ile
        haklarımızı savunmaya aracılık eden birimlerin oluşumlarını, irtibat
        numaralarını ve hangi sorunların çözümü için var oldukları bilgisini
        bünyesinde bulundurmayı hedef belirlemiş bir platformdur.Her Türk
        vatandaşının sorun ve sıkıntılarının çözüm bulmasını kolaylaştırmayı
        hedef alan, bu haklarını kullanmaktan geri kalan, her vatandaşımızın
        yönlendirilme ihtiyacını karşılamayı kendine görev bilen iki eşsiz
        dostun memleketi ve insanları için elinden geleni yapma isteğinin ortaya
        çıkardığı şikayet bilgi sistemi platformuyla vatandaşlarımızın
        mağduriyetlerini gidermek adına amme hizmeti olarak ortaya koyduğumuz
        projedir.Bu platform, var olan sorunları veya sıkıntıları çözümlemekten
        öte sorun veya sıkıntı ile karşılaşan vatandaşların çözüm yollarına
        başvurularına erişimini kolaylaştıran bir yönlendiricidir. sizlerin de
        projemizde eksik gördüğünüz yanları ve geliştirilmesini dilediğiniz
        kısımları hakkında görüşlerinizi info@sikayetbilgisistemi.com adresi
        üzerinden bizlere iletebilir ve siz de milletimiz için bir şeyler yapmış
        olmanın haklı gururunu bizlerle paylaşabilirsiniz.
      </p>
    </div>
  );
}

export default AboutUs;
