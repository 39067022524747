import React from "react";
import {Col, Row } from "reactstrap";

function Footer() {
  return (
    <div style={{marginTop:"7vh"}}>
      
          <Row style={{}}>
            <Col>
            </Col>
            <Col>
              <p
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  fontFamily: "Ubuntu",
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                © Şikayet Bilgi Sistemi
              </p> 
            </Col>
            <Col><p
              style={{
                color: "inherit",
                textDecoration: "none",
                fontFamily: "Ubuntu",
                fontSize: "0.6rem",
                textAlign: "center",
              }}
            >
              <a style={{color:"inherit"}} href="https://lupittus.com"><span>Lupi</span></a> tarafından geliştirilmiştir.
            </p>
            </Col>
          </Row>

    </div>
  );
}

export default Footer;
