import React from "react";
import { Button, Row, Col } from "reactstrap";
import { Link} from "react-router-dom";

function Tabs() {
  return (
    <div
      style={{
        justifyContent: "center",
        justifyItems: "center",
        margin: "auto",
        marginTop: "7vh",
      }}
    >
      <Row>
        <Col
          style={{
            justifyContent: "center",
            justifyItems: "center",
            alignItems: "center",
            margin: "auto",
            padding: "auto",
            display: "flex",
          }}
        >
          <Link
            style={{
              color: "inherit",
              textDecoration: "none",
              fontFamily: "Ubuntu",
            }}
            to="/kamu"
          >
            <Button size="lg" color="danger">
              KAMU
            </Button>
          </Link>
        </Col>
        <Col
          style={{
            justifyContent: "center",
            justifyItems: "center",
            alignItems: "center",
            margin: "auto",
            padding: "auto",
            display: "flex",
          }}
        >
          <Link
            style={{
              color: "inherit",
              textDecoration: "none",
              fontFamily: "Ubuntu",
            }}
            to="/ozel"
          >
            <Button size="lg" color="danger">
              ÖZEL
            </Button>
          </Link>
        </Col>
      </Row>
      <div style={{ marginTop: "7vh" }}>
      </div>
    </div>
  );
}

export default Tabs;
