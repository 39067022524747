import React from "react";
import { Input, Form, Button, Row, Col } from "reactstrap";

function Search() {
  return (
    <div style={{ maxWidth: 900, margin: "auto" }}>
      <Form>
        <Col>
          <a href="/arama">
            <Input />
          </a>

        
        </Col>
      </Form>
    </div>
  );
}

export default Search;
