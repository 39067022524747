import React from "react";
import Tabs from "../Tabs.js";
import { Route, Routes } from "react-router-dom";
import SearchBar from "./SearchBar.js";
import Kamu from "../Kamu.js";
import Ozel from "../Ozel.js";


function Dashboard() {
  return (
    <div style={{ maxWidth: 800, margin: "auto", marginTop: "7vh" }}>
      <SearchBar style={{ marginTop: "7vh" }} />
      <Tabs />
      <Routes>
        <Route path="/kamu" element={<Kamu />} />
        <Route path="/ozel" element={<Ozel />} />
      </Routes>
    </div>
  );
}

export default Dashboard;
