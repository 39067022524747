import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import { UncontrolledAccordion, AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap';

const Ozel = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collectionRef = db.collection('CeUQxGuxj1seUdTTq3V8');
        const snapshot = await collectionRef.get();
        const fetchedData = snapshot.docs.map((doc) => {
          const docData = doc.data();
          const values = JSON.parse(docData.values);
          return {
            id: doc.id,
            ...values.reduce((acc, val) => ({ ...acc, ...val }), {}),
          };
        });
        setData(fetchedData);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <UncontrolledAccordion flush>
        {data.map((item, index) => (
          <AccordionItem key={index}>
            <AccordionHeader targetId={index}>
              <p style={{ fontFamily: 'inherit', marginBottom: 0 }}>
                <span>{item.phone}</span> | <span>{item.title}</span>
              </p>
            </AccordionHeader>
            <AccordionBody accordionId={index}>
              <strong>{item.brief}</strong>
              <br />
              {item.description}
            </AccordionBody>
          </AccordionItem>
        ))}
      </UncontrolledAccordion>
    </div>
  );
};

export default Ozel;
