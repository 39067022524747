import React from "react";
import logo from "../img/logo.png";

function Header() {
  return (
    <div
      style={{
        maxWidth: "1100px",
        margin: "auto",
        justifyContent: "center",
        justifyItems: "center",
      }}
    >
      <a href="/">
        <img
          src={logo}
          alt="Şikayet Bilgi Sistemi Logo"
          width="128px"
          style={{
            margin: "auto",
            display: "flex",
          }}
        ></img>
      </a>
      <h1
        style={{
          textAlign: "center",
          margin: "auto",
          color: "red",
          marginTop: "7vh",
          fontFamily: "Ubuntu",
        }}
      >
        <a href="/" style={{ color: "inherit", textDecoration: "none" }}>
          ŞİKAYET BİLGİ SİSTEMİ
        </a>
      </h1>
      <p
        style={{
          padding: "4vh",
          margin: "auto",
          textAlign: "center",
          marginTop: "7vh",
          fontSize: "1.2rem",
          // borderColor:"black", borderBlock:"solid"
        }}
      >
        <span style={{ fontSize: "1.5rem" }}> Anayasa'nın 36. maddesinde;</span>
        <br />
        "Herkes meşru vasıta ve yollardan faydalanmak suretiyle yargı mercileri
        önünde davacı veya davalı olarak iddia ve savunma ile adil yargılanma
        hakkına sahiptir."
      </p>
    </div>
  );
}

export default Header;
