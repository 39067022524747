import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

//components
import Navbar from "./components/pages/Navbar.js";
import Header from "./components/pages/Header.js";
import Dashboard from "./components/pages/Dashboard.js";
import Search from "./components/Search.js";
import Footer from "./components/Footer.js";
import AboutUs from "./components/AboutUs.js";

function App() {

  function RedirectToAdmin() {
    React.useEffect(() => {
      window.location.href = 'https://admin-sikayet-bilgi-sistemi.web.app/'; 
    }, []);
  
    return null; 
  }
  

  return (
    <div style={{ overflowX: "hidden" }}>
      <Navbar />
      <Header />
      <div style={{ minHeight: "50vh" }}>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/hakkimizda" element={<AboutUs />} />
          <Route path="*" element={<Dashboard />} />
          <Route path="/arama" element={<Search />} />
          <Route path="/admin" element={<RedirectToAdmin />} />
        </Routes>
      </div>
      <Footer />
    </div>
    
  );
}
export default App;
