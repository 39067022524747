// firebaseConfig.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBwIsfwiT29qByDr-ot-kGLW7STSlQ48Uc",

  authDomain: "sikayet-bilgi-sistemi.firebaseapp.com",

  projectId: "sikayet-bilgi-sistemi",

  storageBucket: "sikayet-bilgi-sistemi.appspot.com",

  messagingSenderId: "184155178686",

  appId: "1:184155178686:web:388932221ade1dec6d00ef",

  measurementId: "G-V6QHWDX941"

  };

// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  
  // Initialize Firestore
  const db = firebase.firestore();
  
  export { db };



